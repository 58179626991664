<template>
  <div class="tutorials">
    <div class="procedure">1、选择对应Android版下载</div>
    <div><img class="tutorialsImg" src="@/assets/imgs/and-1.jpg" alt="" /></div>
    <div class="procedure">2、点击下载</div>
    <div><img class="tutorialsImg" src="@/assets/imgs/and-2.png" alt="" /></div>
    <div class="procedure">3、选择继续安装</div>
    <div><img class="tutorialsImg" src="@/assets/imgs/and-3.png" alt="" /></div>
    <div class="procedure">4、点击打开即可使用</div>
    <div><img class="tutorialsImg" src="@/assets/imgs/and-4.png" alt="" /></div>
  </div>
</template>

<style lang="scss" scoped>
.tutorialsImg {
  width: 100%;
}
.procedure {
  line-height: 38px;
  font-weight: 600;
  font-size: 15px;
}
</style>
